import React from "react";
import Heading from "./Heading";
import { Link } from "react-router-dom";
import ScheduleImg from "../images/scheduleImg.png";

const Schedule = () => {
  return (
    <div className="py-12 lg:py-24 bg-troo-tertiary">
      <div className="main_container1">
        <div className="flex flex-col lg:flex-row gap-8">
          <div className="flex flex-col gap-5 lg:w-[60%]">
            <Heading
              title="Dental Care, New York, United State"
              heading="A Better Life Starts With A Beautiful Smile"
            />
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type.
            </p>
            <div className="flex flex-col sm:flex-row gap-10 text-troo-black">
              <div>
                <Link
                  to="/our-service"
                  className="btn1 bg-troo-primary text-troo-white hover:bg-troo-secondary"
                >
                  Explore Services
                </Link>
              </div>
              <div>
                <Link
                  to="/about-us"
                  className="btn1 bg-troo-secondary hover:bg-troo-primary text-troo-white"
                >
                  Learm About Us
                </Link>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2">
            <div className="bg-troo-primary text-troo-white p-4 2xl:p-8 ">
              <h4 className="relative before:content-[''] before:absolute before:bottom-0 before:left-0 before:w-8 before:h-[2px] before:bg-troo-secondary pb-3 mb-6">
                Opening Hours
              </h4>
              <div className="flex flex-col gap-4">
                <div className="flex justify-between gap-5">
                  <p>Sat - Sun</p>
                  <p className="font-bold">08:00AM - 04:00PM</p>
                </div>
                <div className="flex justify-between gap-5">
                  <p>Monday </p>
                  <p className="font-bold">08:00AM - 04:00PM</p>
                </div>
                <div className="flex justify-between gap-5">
                  <p>Tuesday </p>
                  <p className="font-bold">08:00AM - 04:00PM</p>
                </div>
                <div className="flex justify-between gap-5">
                  <p>Wednesday </p>
                  <p className="font-bold">08:00AM - 04:00PM</p>
                </div>
                <div className="flex justify-between gap-5">
                  <p>Thu - Fri</p>
                  <p className="font-bold">08:00AM - 04:00PM</p>
                </div>
              </div>
            </div>
            <img
              className="w-full"
              src={ScheduleImg}
              alt="scheduleImg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Schedule;
