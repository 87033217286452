import { Link, useLocation } from "react-router-dom";
import { FiChevronDown } from "react-icons/fi";

const NavMenu = ({ item }) => {
  const location = useLocation();
  let arr = location.pathname?.split("/");
  let lastvalue = arr[arr.length - 1].trim().replace(/-/g, " ");
  let filt = item?.subNav?.find((s) => s.subTitle === lastvalue);

  return (
    <li className="nav_item h-full">
      {!item?.subNav ? (
        <Link
          to={item.path}
          className={`nav_link py-3 ${
            item.path === location.pathname ? "active" : ""
          }`}
        >
          {item.title}
        </Link>
      ) : (
        <div class="hs-dropdown relative inline-flex">
          <Link
          id="hs-dropdown-basic"
          type="button"
            to={item.path}
            className={`nav_link hs-dropdown-toggle py-3 ${
              item.title === lastvalue || filt ? "active" : ""
            }`}
          >
            {item.title}
            <FiChevronDown className="ml-1" />
          </Link>
          {
            <div className="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 w-56 hidden z-10 mt-2 min-w-[15rem] bg-white shadow-md rounded ">
              {item.subNav.map((e, i) => {
                return (
                  <Link key={i}
                    className={`flex flex-col items-start gap-5 w-full bg-troo-white hover:bg-troo-secondary hover:bg-opacity-10  py-4 px-6  ${
                      e.subTitle === lastvalue ? "draopDownActive " : " "
                    }`}
                    to={e.path}
                  >
                    {e.subTitle}
                  </Link>
                );
              })}
            </div>
          }
        </div>
      )}
    </li>
  );
};

export default NavMenu;
