import React, { useEffect, useState } from "react";
import teamDetails from "../images/teamDetailsImg.png";
import teamDlts1 from "../images/TeamDetailsImg1.png";
import teamDlts2 from "../images/TeamDetailsImg2.png";
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { TeamData } from "../Data/TeamData";

const TeamDetails = () => {
  const { slug } = useParams();
  const [teamData, setTeamData] = useState();

  useEffect(() => {
    const filteredData = TeamData.filter((data) => data.slug === slug);
    setTeamData(filteredData[0]);
  }, [slug]);

  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="flex flex-col lg:flex-row gap-5">
          <div className="bg-troo-tertiary p-7 flex justify-between flex-col gap-8 lg:w-[30%]">
            <div className="flex flex-col gap-3">
              <img src={teamData?.img || teamDetails} alt="teamDetails" />
              <h3>{teamData?.name || "Dr.Barnad Kidman"}</h3>
              <p>{teamData?.sub ||"CEO & Founder - DDS, BDS, DMD"}</p>
            </div>
            <div className="flex flex-col gap-3">
              <h3>Personal Information</h3>
              <p>
                Phone: <span className="font-bold">+44 123 456 7890</span>
              </p>
              <p>
                Email:{" "}
                <span className="font-bold">barnadkidman123@email.com</span>
              </p>
              <p>
                Location:
                <span className="font-bold">380 Albert St, Melborne</span>
              </p>
              <div className="flex mt-5 gap-5">
            <div className="text-troo-primary bg-troo-primary bg-opacity-20 hover:bg-troo-secondary cursor-pointer p-2 rounded transition-all ease-in-out duration-300">
              <FaFacebookF />
            </div>
            <div className="text-troo-primary bg-troo-primary bg-opacity-20 hover:bg-troo-secondary cursor-pointer  p-2 rounded transition-all ease-in-out duration-300">
              <FaInstagram />
            </div>
            <div className="text-troo-primary bg-troo-primary bg-opacity-20 hover:bg-troo-secondary cursor-pointer p-2 rounded transition-all ease-in-out duration-300">
              <FaTwitter />
            </div>
            <div className="text-troo-primary bg-troo-primary bg-opacity-20 hover:bg-troo-secondary cursor-pointer p-2 rounded transition-all ease-in-out duration-300">
              <FaLinkedinIn />
            </div>
          </div>
            </div>
          </div>
          <div className="lg:w-[70%] flex flex-col gap-5 p-5">
            <h2>About Doctor</h2>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything embarrassing
              hidden in the middle of text. All the Lorem Ipsum generators on
              the Internet tend to repeat predefined chunks as necessary, making
              this the first true generator on the Internet. It uses a
              dictionary of over 200 Latin words, combined with a handful of
              model sentence structures.
            </p>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything embarrassing
              hidden in the middle of text.
            </p>
            <p>
              <span className="font-bold">Qualification:</span> MDS, BDS -
              Periodontology and Oral Implantology, 5 Years Experience
            </p>
            <p>
              <span className="font-bold">Certification:</span> Lorem ipsum
              dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore.
            </p>
            <p>
              <span className="font-bold">Professional Skills:</span> Lorem
              ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt.
            </p>
            <p>
              <span className="font-bold">Awards:</span> National Specialist
              Register in Dental
            </p>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything embarrassing.
            </p>
            <div className="flex flex-col sm:flex-row gap-8">
              <img src={teamDlts1} alt="teamImg1" />
              <img src={teamDlts2} alt="teamImg2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamDetails;
