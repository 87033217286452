import React from 'react'
import Layout from '../../Components/Layout'
import HeroGlobal from '../../Components/HeroGlobal'
import PortfolioDetails from '../../Components/PortfolioDetails'
import Schedule from '../../Components/Schedule'
import useDocumentTitle from '../../Hooks/PageTitle'

const WorkDetailsPage = () => {
   useDocumentTitle("TRoo Dental | Case Study Details")
  return (
    <Layout>
        <HeroGlobal title="Aesthetic Dentistry" />
        <PortfolioDetails/>
        <Schedule/>
    </Layout>
  )
}

export default WorkDetailsPage