import React from 'react'
import Layout from '../../Components/Layout'
import img404 from "../../images/img404.png"
import { Link } from 'react-router-dom'
import useDocumentTitle from '../../Hooks/PageTitle'
import HeroGlobal from '../../Components/HeroGlobal'

const ErrorPage = () => {
  useDocumentTitle("TRoo Wedding Planner | Error Page")
  return (
    <Layout>
      <HeroGlobal title="404 Error Page"/>
      <div className="bg-error-bg w-full bg-no-repeat bg-cover bg-center py-12 lg:py-24">
      <div className="main_container1 flex justify-center">
        <div className="flex flex-col text-center gap-9">
          <h2>Oops..</h2>
          <div className="">
            <img
              className="lg:max-w-[400px]   mx-auto"
              src={img404}
              alt={img404}
            />
          </div>
          <h2>Sorry! page did not found</h2>
          <p className="max-w-[600px] text-center m-auto font-Infont">
          There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour.
          </p>
          <div>
            <Link
              to="/"
              className="btn1 hover:bg-troo-secondary bg-troo-primary text-troo-white "
            >
            GO TO HOMEPAGE
            </Link>
          </div>
        </div>
      </div>
    </div>
    </Layout>
  )
}

export default ErrorPage