import React from "react";
import Layout from "../Components/Layout";
import HeroGlobal from "../Components/HeroGlobal";
import Input from "../Components/Input";
import useDocumentTitle from "../Hooks/PageTitle";

const Appointment = () => {
  useDocumentTitle("TRoo Dental | Appointment Page")
  return (
    <Layout>
      <HeroGlobal title="Book An Appointment" />
      <div className="py-12 lg:py-24">
        <div className="main_container1 border shadow py-12">
          <div className="mb-12 flex flex-col gap-5 items-center">
            <h2>Book An Appointment</h2>
            <p className="text-center">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour.
            </p>
          </div>
          <form className="w-full  flex flex-col items-start gap-14">
              <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="flex flex-col gap-5">
                  <label>Full Name</label>
                  <Input placeholder="Name" />
                </div>
                <div className="flex flex-col gap-5">
                  <label>Phone No.</label>
                  <Input placeholder="Phone No" />
                </div>
                <div className="flex flex-col gap-5">
                  <label>Email Address</label>
                  <Input placeholder="Email Address" />
                </div>
                <div className="flex flex-col gap-5">
                  <label>Case Type</label>
                  <Input placeholder="Case Type" />
                </div>
                <div className="flex flex-col gap-5">
                  <label>Select Service</label>
                  <Input placeholder="Select Service" />
                </div>
                <div className="flex flex-col gap-5">
                  <label>Select Doctor</label>
                  <Input placeholder="Dr. Vikash Dubey" />
                </div>
                <div className="flex flex-col gap-5">
                  <label>Date</label>
                  <Input placeholder="Phone No." type="date" />
                </div>
                <div className="flex flex-col gap-5">
                  <label>Time</label>
                  <Input  type="time" />
                </div>
                <div className="flex md:col-span-2 items-start w-full rounded px-5 py-3 justify-between border border-troo-whitefade2">
                  <textarea
                    rows={5}
                    placeholder="Message"
                    className="bg-transparent outline-none w-full"
                  />
                </div>
              </div>
              <button className="btn1 bg-troo-primary text-troo-white hover:bg-troo-black">
                Send a Message
              </button>
            </form>
          <div>

          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Appointment;
