import React from 'react'
import Layout from '../../Components/Layout'
import HeroGlobal from '../../Components/HeroGlobal'
import TeamDetails from '../../Components/TeamDetails'
import useDocumentTitle from '../../Hooks/PageTitle'

const TeamDetailsPage = () => {
  useDocumentTitle("TRoo Dental | Team Details")
  return (
    <Layout>
        <HeroGlobal title="Dr.Barnad Kidman" />
        <TeamDetails/>
    </Layout>
  )
}

export default TeamDetailsPage