import React from "react";
import { AddressData } from "../Data/AddressData";
import Input from "./Input";
import contactImg from "../images/contactImg.png"
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter } from "react-icons/fa";

const Contact = () => {
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="flex lg:flex-row flex-col gap-10">
          <div className="lg:w-[35%] w-full bg-contact-bg bg-cover bg-no-repeat flex flex-col gap-5 bg-troo-primary sm:px-10 px-5 sm:py-8 py-5 text-troo-white">
            <div className="relative before:absolute before:content-[''] before:bg-troo-secondary before:bg-opacity-80 before:w-full before:h-full flex justify-center items-center">
              <img className="w-full" src={contactImg} alt="contactIMG" />
              <h3 className="absolute ">Let’s Communicate</h3>
            </div>
            <div className="flex flex-col gap-8">
              {AddressData.map((e) => (
                <div className="flex gap-5 items-center bg-troo-white bg-opacity-10 rounded p-5">
                  <div className="bg-troo-primary rounded-full p-2 w-12 h-12 flex items-center justify-center text-2xl">
                    {e.icon}
                  </div>
                  <div className="flex flex-col gap-2">
                    <p>{e.name}</p>
                    <h4>{e.para}</h4>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-center items-center gap-5">
              <h5>Social Media</h5>
            <div className="text-troo-white bg-troo-whiteFade hover:bg-troo-secondary cursor-pointer p-2 rounded transition-all ease-in-out duration-300">
              <FaFacebookF />
            </div>
            <div className="text-troo-white bg-troo-whiteFade hover:bg-troo-secondary cursor-pointer  p-2 rounded transition-all ease-in-out duration-300">
              <FaInstagram />
            </div>
            <div className="text-troo-white bg-troo-whiteFade hover:bg-troo-secondary cursor-pointer p-2 rounded transition-all ease-in-out duration-300">
              <FaTwitter />
            </div>
            <div className="text-troo-white bg-troo-whiteFade hover:bg-troo-secondary cursor-pointer p-2 rounded transition-all ease-in-out duration-300">
              <FaLinkedinIn />
            </div>
          </div>

          </div>
          <div className="lg:w-[65%] w-full p-5 md:p-10 shadow-contact">
            <h2 className="mb-8">Have questions? Get in touch!</h2>
            <form className="w-full  flex flex-col items-start gap-14">
              <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="flex flex-col gap-5">
                  <label>Full Name</label>
                  <Input placeholder="Full Name" />
                </div>
                <div className="flex flex-col gap-5">
                  <label>Email Adress</label>
                  <Input placeholder="Email Adress" />
                </div>
                <div className="flex flex-col gap-5">
                  <label>Phone No.</label>
                  <Input placeholder="Phone No." />
                </div>
                <div className="flex flex-col gap-5">
                  <label>Company Name</label>
                  <Input placeholder="Company Name" />
                </div>
                <div className="flex md:col-span-2 items-start w-full rounded px-5 py-3 justify-between border border-troo-whitefade2">
                  <textarea
                    rows={5}
                    placeholder="Message"
                    className="bg-transparent outline-none w-full"
                  />
                </div>
              </div>
              <button className="btn1 bg-troo-secondary text-troo-white hover:bg-troo-black">
                Send a Message
              </button>
            </form>
          </div>
        </div>
        <div className="mt-12">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7343.710423642854!2d72.49508733426413!3d23.029087824997855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9b278e046fa5%3A0xd837def63555f791!2sTRooInbound%20Private%20Limited!5e0!3m2!1sen!2sin!4v1687782488933!5m2!1sen!2sin"
            width="100%"
            height="500"
            style={{ border: "0" }}
            allowfullscreen=""
            loading="lazy"
            title="gmap"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contact;
