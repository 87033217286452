import feedback1 from "../images/feedback1.png";
import feedback2 from "../images/feedback2.png";
import feedback3 from "../images/feedback3.png";
import feedback4 from "../images/feedback4.png";
import feedback5 from "../images/feedback5.png";
import feedback6 from "../images/feedback6.png";
import feedback7 from "../images/feedback7.png";
import feedback8 from "../images/feedback8.png";
import { FaStar } from "react-icons/fa";

export const FeedbackData = [
  {
    id: 1,
    img: feedback1,
    name: "Methew Deep",
    sub: "Teeth Whitening Services",
    title: "Company CEO, Georgia",
    rating: (
      <>
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStar />
      </>
    ),
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.",
  },
  {
    id: 2,
    img: feedback2,
    name: "Megan Duchia",
    sub: "Wisdom tooth removal",
    title: "Marketing Manager, NY",
    rating: (
      <>
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStar />
      </>
    ),
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.",
  },
  {
    id: 3,
    img: feedback3,
    name: "Angelina Bergnaum",
    sub: "Children’s dentistry Services",
    title: "Accountant, LA",
    rating: (
      <>
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStar />
      </>
    ),
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.",
  },
  {
    id: 4,
    img: feedback4,
    name: "Jorge Deckow",
    sub: "Braces Procedures Services",
    title: "Actor, NY",
    rating: (
      <>
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStar />
      </>
    ),
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.",
  },
  {
    id: 5,
    img: feedback5,
    name: "Ron Daugherty",
    sub: " Children’s dentistry Services",
    title: "Student, London",
    rating: (
      <>
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStar />
      </>
    ),
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.",
  },
  {
    id: 6,
    img: feedback6,
    name: "Viola Hills",
    sub: "Teeth Whitening Services",
    title: "Lawyer, Pencilvenia",
    rating: (
      <>
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStar />
      </>
    ),
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.",
  },
  {
    id: 7,
    img: feedback7,
    name: "Shannon Ullrich",
    sub: "Children’s dentistry Services",
    title: "Newsreader, Philadelfia",
    rating: (
      <>
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStar />
      </>
    ),
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.",
  },
  {
    id: 8,
    img: feedback8,
    name: "Greg Weissnat",
    sub: "Teeth Whitening Services",
    title: "Real estate agent, Denver",
    rating: (
      <>
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStar />
      </>
    ),
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.",
  },

];
