import service1 from "../images/service1.png"
import service2 from "../images/service2.png"
import service3 from "../images/service3.png"
import service4 from "../images/service4.png"
import service5 from "../images/service5.png"
import service6 from "../images/service6.png"
import service7 from "../images/service7.png"
import service8 from "../images/service8.png"
import service9 from "../images/service9.png"
import sIcon1 from "../images/serviceIcon1.svg"
import sIcon2 from "../images/serviceIcon2.svg"
import sIcon3 from "../images/serviceIcon3.svg"

export const ServiceData =[
    {
        id : 1,
        img : service1,
        name : "General Dentistry",
        slug : "general-dentistry",
        sIcon : sIcon1,
        para : "There are many variations of also passages of Lorem Ipsum available, but the it is majority have suffered alteration in some form, by injected humour,"
    },
    {
        id : 2,
        img : service2,
        name : "Dental Cleaning",
        slug : "dental-cleaning",
        sIcon : sIcon2,
        para : "There are many variations of also passages of Lorem Ipsum available, but the it is majority have suffered alteration in some form, by injected humour,"
    },
    {
        id : 3,
        img : service3,
        name : "Core Build-Up",
        slug : "core-build-up",
        sIcon : sIcon3,
        para : "There are many variations of also passages of Lorem Ipsum available, but the it is majority have suffered alteration in some form, by injected humour,"
    },
    {
        id : 4,
        img : service4,
        name : "Root Canal",
        slug : "root-canal",
        sIcon : sIcon2,
        para : "There are many variations of also passages of Lorem Ipsum available, but the it is majority have suffered alteration in some form, by injected humour,"
    },
    {
        id : 5,
        img : service5,
        name : "Alignment Teeth",
        slug : "alignment-teeth",
        sIcon : sIcon1,
        para : "There are many variations of also passages of Lorem Ipsum available, but the it is majority have suffered alteration in some form, by injected humour,"
    },
    {
        id : 6,
        img : service6,
        name : "Oral Hygiene",
        slug : "oral-hygine",
        sIcon : sIcon3,
        para : "There are many variations of also passages of Lorem Ipsum available, but the it is majority have suffered alteration in some form, by injected humour,"
    },
    {
        id : 7,
        img : service7,
        name : "Dental fillings",
        slug : "dental-filling",
        sIcon : sIcon3,
        para : "There are many variations of also passages of Lorem Ipsum available, but the it is majority have suffered alteration in some form, by injected humour,"
    },
    {
        id : 8,
        img : service8,
        name : "General Dentistry",
        slug : "general-dentistry",
        sIcon : sIcon1,
        para : "There are many variations of also passages of Lorem Ipsum available, but the it is majority have suffered alteration in some form, by injected humour,"
    },
    {
        id : 9,
        img : service9,
        name : "Dental fissure sealants",
        slug : "dental-fissure-sealants",
        sIcon : sIcon2,
        para : "There are many variations of also passages of Lorem Ipsum available, but the it is majority have suffered alteration in some form, by injected humour,"
    },

]