import React from "react";
import { motion } from "framer-motion";

const Heading = ({ heading, title, textLight, isCenter }) => {
  return (
    <motion.div
      initial={{ scale: 0.7 }}
      whileInView={{ scale: 1 }}
      transition={{ duration: 1, type: "spring", stiffness: 100 }}
      className={`flex flex-col  gap-4 ${
        textLight ? "text-troo-white" : "text-troo-black"
      } ${isCenter ? "text-center items-center" : "text-left"}`}
    >
      <div className="flex gap-2 items-center">
        <div className="flex gap-1">
          <div
            className={`w-5 h-[2px] ${
              textLight ? "bg-troo-white" : "bg-troo-black"
            } `}
          ></div>
        </div>
        <h4>{title}</h4>
      </div>
      <h2 className="max-w-[750px]">{heading}</h2>
    </motion.div>
  );
};

export default Heading;
