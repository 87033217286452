import React, { useEffect, useState } from "react";
import Heading from "./Heading";
import { Link } from "react-router-dom";
import { WorkData } from "../Data/WorkData";
import { GoArrowUpRight } from "react-icons/go";

const Work = ({ isHome }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (isHome) {
      setData(WorkData.slice(0, 6));
    } else {
      setData(WorkData);
    }
  }, []);
  return (
    <div className="w-[97%] mx-auto">
      <div
        className={`py-12 lg:py-24 ${
          isHome ? "bg-work-Bg bg-no-repeat bg-troo-primary" : ""
        }`}
      >
        <div className="main_container1">
          {isHome && (
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-12">
              <div>
                <Heading
                  title="Our Recent Work"
                  heading={
                    <>
                      Our Cases & Patient Stories <br />
                      We Create Beautiful Smiles
                    </>
                  }
                  textLight
                />
              </div>
              <div className="flex flex-col gap-5 text-troo-white">
                <p>
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                </p>
                <div>
                  <Link
                    to="/case-study"
                    className="btn1 bg-transparent border-troo-white hover:bg-troo-secondary hover:text-troo-white"
                  >
                    View All Case Studies
                  </Link>
                </div>
              </div>
            </div>
          )}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {data.map((e, i) => (
              <Link to={`/case-study/${e.slug}`}
                className="relative group before:content-[''] before:absolute hover:before:w-full before:w-0 hover:before:h-full before:h-0 before:bg-troo-black before:bg-opacity-70  before:transition-all before:ease-in-out before:duration-500"
                key={i}
              >
                <img className="w-full" src={e.img} alt={e.name} />
                <div className="absolute text-troo-white p-5 left-1/2 -translate-x-1/2 w-[90%] bottom-0 bg-troo-primary flex flex-col gap-3 scale-0 group-hover:scale-100 transition-all ease-out duration-500 items-center">
                  <h3 className="text-center">{e.name}</h3>
                  <p>{e.sub}</p>
                </div>
                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 flex flex-col items-center gap-3 scale-0 group-hover:scale-100 transition-all ease-in-out duration-500">
                  <h4 className="text-troo-white">View Detail</h4>
                  <div className="rounded-full p-2 w-10 h-10 flex justify-center items-center text-troo-white bg-troo-secondary">
                    <GoArrowUpRight />
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
