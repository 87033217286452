import React from "react";
import highlightImg from "../images/highlightImg.png";
import { FiUsers } from "react-icons/fi";
import Heading from "./Heading";
import { FiCheck } from "react-icons/fi";
import Counter from "./Counter";

const Highlight = () => {
  return (
    <div className="bg-highlight-bg bg-no-repeat lg:pt-60 pt-12 pb-12 lg:pb-24 bg-troo-primary">
      <div className="main_container1">
        <div className="flex flex-col lg:flex-row gap-8">
          <div className="flex flex-col gap-8 text-troo-white lg:w-[70%]">
            <Heading
              title="Why Choose TRoo Themes"
              heading="We Provide Best Services For Our Clients"
              textLight
            />
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
              <h4 className="flex items-center gap-3">
                <FiCheck className="w-6 h-6 p-1 bg-troo-secondary rounded-full" />
                Provide Digital Inovation
              </h4>
              <h4 className="flex items-center gap-3">
                <FiCheck className="w-6 h-6 p-1 bg-troo-secondary rounded-full" />
                Provide Digital Inovation
              </h4>
              <h4 className="flex items-center gap-3">
                <FiCheck className="w-6 h-6 p-1 bg-troo-secondary rounded-full" />
                Provide Digital Inovation
              </h4>
              <h4 className="flex items-center gap-3">
                <FiCheck className="w-6 h-6 p-1 bg-troo-secondary rounded-full" />
                Provide Digital Inovation
              </h4>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
              <img className="w-full" src={highlightImg} alt="highlightImg" />
            <div className="flex flex-col gap-5 text-troo-white">
              <div className="flex flex-col gap-3 items-center border p-5 border-troo-secondary border-opacity-10 hover:bg-troo-secondary hover:bg-opacity-10 transition-all duration-500 ease-in-out">
                <FiUsers className="text-troo-secondary text-2xl" />
                <h4>100% Client Satisfaction</h4>
                <p className="text-center">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
              <div className="flex flex-col gap-3 items-center border border-troo-secondary border-opacity-10 p-5 hover:bg-troo-secondary hover:bg-opacity-10 transition-all duration-500 ease-in-out">
                <FiUsers className="text-troo-secondary text-2xl" />
                <h4>100% Client Satisfaction</h4>
                <p className="text-center">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Counter/>
      </div>
    </div>
  );
};

export default Highlight;
