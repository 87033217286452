import React from "react";
import Input from "./Input";

const BlogForm = () => {
  return (
    <div className="py-12 ">
      <div className="main_container1">
        <div className="bg-troo-tertiary p-10">
          <h3 className="mb-5">Leave A Comments</h3>
          <form className="flex flex-col gap-5">
            <div className="flex flex-col gap-3">
              <level>Write A Comments</level>
              <textarea
                rows={5}
                placeholder="Write Message"
                className="bg-transparent outline-none border border-troo-black border-opacity-20 rounded px-5 py-3 w-full"
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
              <div className="flex flex-col gap-3">
                <level>Full Name</level>
                <Input placeholder="Name" />
              </div>
              <div className="flex flex-col gap-3">
                <level>Phone</level>
                <Input placeholder="Phone No" />
              </div>
              <div className="flex flex-col gap-3">
                <level>Email Address</level>
                <Input placeholder="Email Address" />
              </div>
            </div>
            <div>
                <button className="btn1 bg-troo-primary text-troo-white hover:bg-troo-secondary">Book An Appointment</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BlogForm;
