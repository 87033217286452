import React from 'react'
import Layout from '../../Components/Layout'
import HeroGlobal from '../../Components/HeroGlobal'
import Testimonial from '../../Components/Testimonial'
import useDocumentTitle from '../../Hooks/PageTitle'

const TestimonialPage = () => {
  useDocumentTitle("TRoo Dental | Testimonials Page")
  return (
    <Layout>
        <HeroGlobal title="Our Clients Awesome Feedback" />
        <Testimonial/>
    </Layout>
  )
}

export default TestimonialPage