import team1 from "../images/team1.png"
import team2 from "../images/team2.png"
import team3 from "../images/team3.png"
import team4 from "../images/team4.png"
import team5 from "../images/team5.png"
import team6 from "../images/team6.png"
import team7 from "../images/team7.png"
import team8 from "../images/team8.png"
import team9 from "../images/team9.png"
import {
    FaFacebookF,
    FaInstagram,
    FaTwitter,
    FaLinkedinIn,
  } from "react-icons/fa";


  export const TeamData =[
    {
        id :1,
        img : team1,
        name :"Dr.Barnad Kidman",
        slug : "barnad-kidman",
        sub : "CEO & Founder",
        social: [
            {
              icon: <FaFacebookF />,
              path: "",
            },
            {
              icon: <FaInstagram />,
              path: "",
            },
            {
              icon: <FaTwitter />,
              path: "",
            },
            {
              icon: <FaLinkedinIn />,
              path: "",
            },
          ],
    },
    {
        id :2,
        img : team2,
        name :"Dr.Hililka",
        slug : "hililka",
        sub : "Dental Speciliest",
        social: [
            {
              icon: <FaFacebookF />,
              path: "",
            },
            {
              icon: <FaInstagram />,
              path: "",
            },
            {
              icon: <FaTwitter />,
              path: "",
            },
            {
              icon: <FaLinkedinIn />,
              path: "",
            },
          ],
    },
    {
        id :3,
        img : team3,
        name :"Dr.Charlot Anderson",
        slug : "charlot-anderson",
        sub : "Bridal Mack Up Artist",
        social: [
            {
              icon: <FaFacebookF />,
              path: "",
            },
            {
              icon: <FaInstagram />,
              path: "",
            },
            {
              icon: <FaTwitter />,
              path: "",
            },
            {
              icon: <FaLinkedinIn />,
              path: "",
            },
          ],
    },
    {
        id :4,
        img : team4,
        name :"Dr.Jenny Scooter",
        slug : "jenny-scooter",
        sub : "CEO and Founder",
        social: [
            {
              icon: <FaFacebookF />,
              path: "",
            },
            {
              icon: <FaInstagram />,
              path: "",
            },
            {
              icon: <FaTwitter />,
              path: "",
            },
            {
              icon: <FaLinkedinIn />,
              path: "",
            },
          ],
    },
    {
        id :5,
        img : team5,
        name :"Dr.Stefanie Jordan",
        slug : "stefanie-jorden",
        sub : "Surgen",
        social: [
            {
              icon: <FaFacebookF />,
              path: "",
            },
            {
              icon: <FaInstagram />,
              path: "",
            },
            {
              icon: <FaTwitter />,
              path: "",
            },
            {
              icon: <FaLinkedinIn />,
              path: "",
            },
          ],
    },
    {
        id :6,
        img : team6,
        name :"Dr.Fanny Navarro",
        slug : "fanny-navarro",
        sub : "Co Founder",
        social: [
            {
              icon: <FaFacebookF />,
              path: "",
            },
            {
              icon: <FaInstagram />,
              path: "",
            },
            {
              icon: <FaTwitter />,
              path: "",
            },
            {
              icon: <FaLinkedinIn />,
              path: "",
            },
          ],
    },
    {
        id :7,
        img : team7,
        name :"Dr.Erin Romero",
        sub : "Dental Speciliest",
        social: [
            {
              icon: <FaFacebookF />,
              path: "",
            },
            {
              icon: <FaInstagram />,
              path: "",
            },
            {
              icon: <FaTwitter />,
              path: "",
            },
            {
              icon: <FaLinkedinIn />,
              path: "",
            },
          ],
    },
    {
        id :8,
        img : team8,
        name :"Dr.Della Berg",
        slug : "della-berg",
        sub : "SR. Manager",
        social: [
            {
              icon: <FaFacebookF />,
              path: "",
            },
            {
              icon: <FaInstagram />,
              path: "",
            },
            {
              icon: <FaTwitter />,
              path: "",
            },
            {
              icon: <FaLinkedinIn />,
              path: "",
            },
          ],
    },
    {
        id :9,
        img : team9,
        name :"Dr.Forest Cherry",
        slug : "forest-cherry",
        sub : "Sugen Dental",
        social: [
            {
              icon: <FaFacebookF />,
              path: "",
            },
            {
              icon: <FaInstagram />,
              path: "",
            },
            {
              icon: <FaTwitter />,
              path: "",
            },
            {
              icon: <FaLinkedinIn />,
              path: "",
            },
          ],
    },
   
]
