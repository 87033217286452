import blog1 from "../images/blog1.png";
import blog2 from "../images/blog2.png";
import blog3 from "../images/blog3.png";
import blog4 from "../images/blog4.png";
import blog5 from "../images/blog5.png";
import blog6 from "../images/blog6.png";
import blog7 from "../images/blog7.png";
import blog8 from "../images/blog8.png";
import blog9 from "../images/blog9.png";

export const BlogData = [
  {
    id: 1,
    img: blog1,
    name: "4 Questions You Should Consider Before Choosing a New Dentist",
    slug: "4-questions-you-should-consider-before-choosing-a-new-dentist",
    sub: "Dental Care",
  },
  {
    id: 2,
    img: blog2,
    name: "What Are the Best Options to Replace Missing Teeth?",
    slug: "what-are-the-best-options-to-replace-missing-teeth",
    sub: "Missing  Teeth",
  },
  {
    id: 3,
    img: blog3,
    name: "Did You Know These Food Items Could Be the Culprits Behind Bad Breath?",
    slug: "did-you-know-these-food-items-could-be-the-culprits-behind-bad-breath",
    sub: "Missing  Teeth",
  },
  {
    id: 4,
    img: blog4,
    name: "5 Facts About Teeth Whitening You Should Know",
    slug: "5-facts-about-teeth-whitening-you-should-know",
    sub: "Dental Care",
  },
  {
    id: 5,
    img: blog5,
    name: "What is a crossbite? Signs, effects and treatment",
    slug: "what-is-a-crossbite-signs-effects-and-treatment",
    sub: "Missing  Teeth",
  },
  {
    id: 6,
    img: blog6,
    name: "Say Goodbye to Missing Teeth: 6 Benefits of Dental Implants You Can’t Ignore!",
    slug: "say-goodbye-to-missing-teeth-6-benefits-of-dental-implants-you-cant-ignore",
    sub: "Teeth Grinding",
  },
  {
    id: 7,
    img: blog7,
    name: "Are Processed Foods Putting Your Dental Health at Risk?",
    slug: "are-processed-foods-putting-your-dental-health-at-risk",
    sub: "Missing  Teeth",
  },
  {
    id: 8,
    img: blog8,
    name: "Restoring Missing Teeth: Dental Implants Vs. Dentures Vs. Bridges",
    slug: "restoring-missing-teeth-dental-implants-vs-dentures-vs-bridges",
    sub: "Dental Care",
  },
  {
    id: 9,
    img: blog9,
    name: "Restoring Implant Health: Treatment Options for Peri-implantitis",
    slug: "restoring-implant-health-treatment-options-for-peri-implantitis",
    sub: "Teeth Grinding",
  },
];
