import React, { useEffect, useState } from "react";
import Heading from "./Heading";
import { ServiceData } from "../Data/ServiceData";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";

const Service = ({ isHome }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (isHome) {
      setData(ServiceData.slice(0, 6));
    } else {
      setData(ServiceData);
    }
  }, []);
  return (
    <div className="lg:py-24 py-12 bg-troo-tertiary">
      <div className="main_container1">
        {isHome && (
          <div className="mb-12">
            <Heading
              title="It is a timer for a smile"
              heading="Different Types Of Services"
              isCenter
            />
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {data.map((e, i) => (
            <div className="">
              <Link to={`/our-service/${e.slug}`} className="group" key={i}>
                <div className="flex flex-col justify-center items-center gap-3 px-5 py-10 bg-troo-white shadow">
                  <h3>{e.name}</h3>
                  <p className="text-center">{e.para}</p>
                  <button className="flex items-center gap-3 font-Jost text-base font-bold group-hover:text-troo-primary">
                    Read More <BsArrowRight />
                  </button>
                </div>
                <div className="relative flex justify-center before:content-[''] before:absolute group-hover:before:w-full before:w-0 group-hover:before:h-full before:h-0 before:bg-troo-black before:bg-opacity-70  before:transition-all before:ease-in-out before:duration-500">
                  <img className="w-full" src={e.img} alt={e.name} />
                  <div className="absolute -top-5">
                    <div className="bg-troo-primary rounded-full w-12 h-12 flex justify-center items-center p-2">
                      <img src={e.sIcon} alt={e.name} />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
        {isHome && (
          <div className="mt-12 text-center">
            <Link to="/our-service" className="btn1 bg-transparent border-troo-primary hover:bg-troo-primary hover:text-troo-white">
              Explore All Services
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Service;
