import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import heroImg from "../images/heroImg.png";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { TbDiscountCheck } from "react-icons/tb";
import heroEmoj from "../images/heroEmojs.png";

const HeroSection = () => {
  return (
    <div className=" top-0 w-full">
      <div className="bg-hero-bg bg-no-repeat bg-cover w-full  relative bg-troo-tertiary">
        <div className="main_container1 pt-36  grid grid-cols-1 lg:grid-cols-2 gap-10">
          <div className="flex flex-col justify-center gap-8 py-12">
            <div className="flex items-center gap-5">
              <div className="w-5 h-[3px] bg-troo-black"></div>
              <h4>It is a timer for a smile</h4>
            </div>
            <motion.h1
              initial={{ scale: 0.7 }}
              whileInView={{ scale: 1 }}
              transition={{ duration: 1, type: "spring", stiffness: 100 }}
            >
              Brighten Teeth with Brighten Care
            </motion.h1>
            <p className="max-w-[450px]">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour.
            </p>
            <div className="flex flex-col sm:flex-row gap-10 text-troo-black">
              <div>
                <Link
                  to="/our-service"
                  className="btn1 bg-troo-primary text-troo-white"
                >
                  Explore Services
                </Link>
              </div>
              <div>
                <Link
                  to="/about-us"
                  className="btn1 bg-transparent border-troo-primary hover:bg-troo-primary hover:text-troo-white"
                >
                  Learm About Us
                </Link>
              </div>
            </div>
            <div className="flex items-center gap-5 lg:pt-36">
              <p>Follow Us on Social :</p>
              <div className="bg-troo-primary bg-opacity-20 hover:bg-opacity-100 text-troo-primary hover:text-troo-white p-2 rounded transition-all ease-in-out duration-300">
                <FaFacebookF />
              </div>
              <div className="bg-troo-primary bg-opacity-20 hover:bg-opacity-100 text-troo-primary hover:text-troo-white p-2 rounded transition-all ease-in-out duration-300">
                <FaInstagram />
              </div>
              <div className="bg-troo-primary bg-opacity-20 hover:bg-opacity-100 text-troo-primary hover:text-troo-white p-2 rounded transition-all ease-in-out duration-300">
                <FaTwitter />
              </div>
              <div className="bg-troo-primary bg-opacity-20 hover:bg-opacity-100 text-troo-primary hover:text-troo-white p-2 rounded transition-all ease-in-out duration-300">
                <FaLinkedinIn />
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center relative">
            <img src={heroImg} alt="heroImg" />
            <div className="absolute hidden  p-4 sm:flex  bg-troo-whiteFade shadow-lg gap-5 rounded left-16 top-40">
              <div className="bg-troo-primary p-1 text-troo-white rounded">
                <TbDiscountCheck />
              </div>
              <p>Regular Chack-Up</p>
            </div>
            <div className="absolute hidden bg-troo-white p-5 sm:flex flex-col gap-3 w-64 bottom-20 right-0 2xl:-right-36 rounded">
              <h4>Happiest Clients</h4>
              <img src={heroEmoj} alt="heroemoj" />
              <p>There are many variations of passages of Lorem Ipsum.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
