import React from "react";
import { Link } from "react-router-dom";
import { BlogData } from "../Data/BlogData";

const RelatedBlog = () => {
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <h2 className="mb-12 pb-5 border-b border-troo-primary border-opacity-20">Related Blogs</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {BlogData.slice(0,3).map((e, i) => (
            <Link to={`/our-blogs/${e.slug}`} className="group" key={i}>
              <div className="relative">
                <img className="w-full" src={e.img} alt={e.img} />
                <div className="absolute top-5 left-5 ">
                  <p className="bg-troo-primary text-troo-white p-2 rounded">
                    {e.sub}
                  </p>
                </div>
              </div>
              <div className="flex flex-col p-4 lg:p-8 gap-5 shadow-lg lg:w-[80%] bg-troo-white relative z-[1] -mt-20 rounded">
                <p>
                  23 July
                  <span className="p-1 bg-troo-black text-troo-white group-hover:bg-troo-primary">
                    
                    2023
                  </span>
                </p>
                <h4 className="group-hover:text-troo-primary">{e.name}</h4>
              </div>
            </Link>
          ))}
            
        </div>
      </div>
    </div>
  );
};

export default RelatedBlog;
