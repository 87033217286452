import React, { useEffect, useState } from "react";
import portfolioImg from "../images/portfolioDetailsImg.png";
import { BsCheckCircle, BsPerson, BsTelephone } from "react-icons/bs";
import teeth1 from "../images/teeth1.png";
import teeth2 from "../images/teeth2.png";
import Input from "./Input";
import { IoMailOpenOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { WorkData } from "../Data/WorkData";

const PortfolioDetails = () => {
  const { slug } = useParams();
  const [workData, setWorkData] = useState();

  useEffect(() => {
    const filteredData = WorkData.filter((data) => data.slug === slug);
    setWorkData(filteredData[0]);
  }, [slug]);
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="relative mb-8">
          <img  src={portfolioImg} alt="portfoliDetails" />
          <div className="p-4 sm:p-8 absolute bottom-0 left-0 bg-troo-white">
            <h2 className="text-troo-primary">{workData?.name  || "Aesthetic Dentistry"}</h2>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row h-full items-start gap-6 ">
          <div className="w-full lg:w-[70%] flex flex-col items-start gap-5">
            <h3>Project Overview</h3>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything embarrassing
              hidden in the middle of text. All the Lorem Ipsum generators on
              the Internet tend to repeat predefined chunks as necessary, making
              this the first true generator on the Internet. It uses a
              dictionary of over 200 Latin words, combined with a handful of
              model sentence structures.
            </p>
            <h3>Problem</h3>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything embarrassing
              hidden in the middle of text. All the Lorem Ipsum generators on
              the Internet tend to repeat predefined chunks as necessary, making
              this the first true.
            </p>
            <h3>Solution</h3>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything embarrassing
              hidden in the middle.
            </p>
            <h3>Process</h3>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going
            </p>
            <div className="flex flex-col gap-4 py-3">
              <div className="flex gap-3">
                <div>
                  <BsCheckCircle className="text-troo-primary w-5 h-5" />
                </div>
                <p>
                  Lorem ipsum dolor Vestibulum velit tortor, interdum sed cursus
                  eu, sagittis ut nunc.
                </p>
              </div>
              <div className="flex gap-3">
                <div>
                  <BsCheckCircle className="text-troo-primary w-5 h-5" />
                </div>
                <p>
                  Interdum sed cursus eu, sagittis ut nunc. Sed vitae tellus et
                  arcu aliquet faucibus fermentum non lacus.L
                </p>
              </div>
              <div className="flex gap-3">
                <div>
                  <BsCheckCircle className="text-troo-primary w-5 h-5" />
                </div>
                <p>
                  Lorem ipsum dolor Vestibulum velit tortor, interdum sed cursus
                  eu, sagittis ut nunc. Sed vitae tellus.
                </p>
              </div>
              <div className="flex gap-3">
                <div>
                  <BsCheckCircle className="text-troo-primary w-5 h-5" />
                </div>
                <p>Lorem ipsum dolor Vestibulum velit tortor, interdum sed .</p>
              </div>
            </div>
            <h3>Before - After Image</h3>
            <div className="flex flex-col md:flex-row gap-5 w-full">
              <img className="w-full rounded" src={teeth1} alt="teeth1" />
              <img className="w-full rounded" src={teeth2} alt="teeth2" />
            </div>
          </div>
          <div className="w-full md:sticky top-40 lg:w-[30%]">
            <div className="bg-troo-tertiary p-4 sm:p-8 mb-8">
              <h3 className="mb-5">Project Detail</h3>
              <div className="flex flex-col gap-5">
                <p className="justify-between flex gap-5">
                  <span className="font-bold">Project Detail</span>
                  Prosthodontics Dentist
                </p>
                <p className="justify-between flex gap-5">
                  <span className="font-bold">Client Name</span>
                  Linda Joe
                </p>
                <p className="justify-between flex gap-5">
                  <span className="font-bold">Category</span>
                  Prosthodontics
                </p>
                <p className="justify-between flex gap-5">
                  <span className="font-bold">Date</span>
                  25 July, 2023
                </p>
                <p className="justify-between flex gap-5">
                  <span className="font-bold">Location</span>
                  Califorina, USA
                </p>
              </div>
            </div>
            <div className="flex flex-col items-start gap-6 bg-troo-primary  p-8 ">
              <h4 className="relative text-troo-white  pb-3">
                Quick An Appointment
              </h4>
              <div className="w-full">
                <form className="flex flex-col gap-8 w-full">
                  <Input
                    isservice={true}
                    placeholder="Full Name"
                    icon={<BsPerson />}
                  />
                  <Input
                    isservice={true}
                    placeholder="Email Adress"
                    icon={<IoMailOpenOutline />}
                  />
                  <Input
                    isservice={true}
                    placeholder="Phone No."
                    icon={<BsTelephone />}
                  />
                  <Input
                    isservice={true}
                    placeholder="Select Doctor"
                    icon={<BsPerson />}
                  />
                  <Input
                    isservice={true}
                    placeholder="Company Name"
                    icon={<BsPerson />}
                  />
                  <Input isservice={true} type="date" />
                  <Input isservice={true} type="time" />
                  <button className="btn1 flex justify-center bg-troo-secondary hover:bg-troo-primary text-troo-white border-none">
                    Book An Appointment
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioDetails;
