import React from 'react'
import Layout from '../../Components/Layout'
import HeroGlobal from '../../Components/HeroGlobal'
import BlogDetails from '../../Components/BlogDetails'
import BlogForm from '../../Components/BlogForm'
import RelatedBlog from '../../Components/RelatedBlog'
import useDocumentTitle from '../../Hooks/PageTitle'

const BlogDetailsPage = () => {
  useDocumentTitle("TRoo Dental | Blog Details")
  return (
    <Layout>
        <HeroGlobal title="What Are the Best Options to Replace Missing Teeth?" />
        <BlogDetails/>
        <BlogForm/>
        <RelatedBlog/>
    </Layout>
  )
}

export default BlogDetailsPage