import React from 'react'
import Layout from '../../Components/Layout'
import HeroGlobal from '../../Components/HeroGlobal'
import PricingCard from '../../Components/PricingCard'
import useDocumentTitle from '../../Hooks/PageTitle'

const PricingPlanPage = () => {
  useDocumentTitle("TRoo Dental | Pricing Plan")
  return (
    <Layout>
        <HeroGlobal title="Reasnable Pricing Plans" />
        <PricingCard/>
    </Layout>
  )
}

export default PricingPlanPage