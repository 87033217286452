import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Routes/Home";
import AboutPage from "./Routes/AboutPage";
import ServicePage from "./Routes/Service/ServicePage";
import ServiceDetailsPage from "./Routes/Service/ServiceDetailsPage";
import WorkPage from "./Routes/Pages/WorkPage";
import WorkDetailsPage from "./Routes/Pages/WorkDetailsPage";
import TeamPage from "./Routes/Pages/TeamPage";
import TeamDetailsPage from "./Routes/Pages/TeamDetailsPage";
import TestimonialPage from "./Routes/Pages/TestimonialPage";
import FaqPage from "./Routes/Pages/FaqPage";
import PricingPlanPage from "./Routes/Pages/PricingPlanPage";
import ErrorPage from "./Routes/Pages/ErrorPage";
import ComingSoonPage from "./Routes/Pages/ComingSoonPage";
import BlogPage from "./Routes/Blogs/BlogPage";
import BlogDetailsPage from "./Routes/Blogs/BlogDetailsPage";
import ContactPage from "./Routes/ContactPage";
import Appointment from "./Routes/Appointment";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutPage />} />
          <Route path="/our-service" element={<ServicePage />} />
          <Route path="/our-service/:slug" element={<ServiceDetailsPage />} />
          <Route path="/case-study" element={<WorkPage />} />
          <Route path="/case-study/:slug" element={<WorkDetailsPage />} />
          <Route path="/our-team" element={<TeamPage />} />
          <Route path="/our-team/:slug" element={<TeamDetailsPage />} />
          <Route path="/testimonial" element={<TestimonialPage />} />
          <Route path="/faq" element={<FaqPage />} />
          <Route path="/pricing-card" element={<PricingPlanPage />} />
          <Route path="/error-404" element={<ErrorPage />} />
          <Route path="/coming-soon" element={<ComingSoonPage />} />
          <Route path="/our-blogs" element={<BlogPage />} />
          <Route path="/our-blogs/:slug" element={<BlogDetailsPage />} />
          <Route path="/contact-us" element={<ContactPage />} />
          <Route path="/book-an-appointmen" element={<Appointment />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
