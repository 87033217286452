import React, { useEffect, useState } from "react";
import Heading from "./Heading";
import { Link } from "react-router-dom";
import { FeedbackData } from "../Data/FeedbackData";
import { BiSolidQuoteAltLeft } from "react-icons/bi";

const Testimonial = ({ isHome }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (isHome) {
      setData(FeedbackData.slice(0, 6));
    } else {
      setData(FeedbackData);
    }
  }, []);
  return (
    <div className={` ${isHome ? "lg:w-[90%] relative -mb-28  mx-auto" : " w-full"}`}>
      <div
        className={` py-12 lg:py-24  ${
          isHome ? "bg-feedback-bg bg-no-repeat bg-troo-tertiary" : ""
        }`}
      >
        <div className="main_container1">
          {isHome && (
            <div className="flex flex-col gap-5 sm:flex-row justify-between mb-12">
              <Heading
                title="Awesome Feedback"
                heading={
                  <>
                    What our Sweet Clients <br />
                    Say About Us Care
                  </>
                }
              />
              <div>
                <Link to="/testimonial" className="btn1 bg-troo-primary text-troo-white border-troo-white hover:bg-troo-secondary hover:text-troo-white">
                  View All Feedback
                </Link>
              </div>
            </div>
          )}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-8">
            {data.map((e, i) => (
              <div className="flex flex-col gap-8 p-8 bg-troo-white rounded group">
                <div className="flex flex-col sm:flex-row  gap-8" key={i}>
                  <div className="inline-flex sm:block">
                    <div className="border-[5px] border-troo-white outline outline-[5px] outline-troo-secondary group-hover:outline-troo-primary rounded relative transition-all duration-500">
                      <img className="w-32" src={e.img} alt={e.name} />
                      <div className="absolute -top-6 -right-6 bg-troo-secondary group-hover:bg-troo-primary text-troo-white p-2 rounded transition-all duration-500">
                        <BiSolidQuoteAltLeft />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col xl:flex-row justify-between gap-5 w-full">
                    <div className="flex flex-col gap-3">
                      <h4>{e.name}</h4>
                      <p>{e.title}</p>
                      <p>
                        About:<span className="font-bold">{e.sub}</span>
                      </p>
                    </div>
                    <div className="flex  xl:flex-col xl:items-end xl:justify-center justify-between items-center gap-3">
                      <p>5 out off 4.5</p>
                      <div className="flex gap-2 p-2 bg-troo-tertiary text-troo-primary rounded">
                        {e.rating}
                      </div>
                    </div>
                  </div>
                </div>
                <p>{e.para}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
