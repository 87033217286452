import React, { useEffect, useState } from "react";
import Heading from "./Heading";
import { BlogData } from "../Data/BlogData";
import { Link } from "react-router-dom";

const Blogs = ({ isHome }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (isHome) {
      setData(BlogData.slice(0, 6));
    } else {
      setData(BlogData);
    }
  }, []);
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        {isHome && (
          <div className="mb-12">
            <Heading
              title="Blogs & Articles"
              heading={
                <>
                  You Get Every Single <br />
                  News and Blogs
                </>
              }
              isCenter
            />
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {data.map((e, i) => (
            <Link to={`/our-blogs/${e.slug}`} className="group" key={i}>
              <div className="relative">
                <img className="w-full" src={e.img} alt={e.img} />
                <div className="absolute top-5 left-5 ">
                  <p className="bg-troo-primary text-troo-white p-2 rounded">
                    {e.sub}
                  </p>
                </div>
              </div>
              <div className="flex flex-col p-4 lg:p-8 gap-5 shadow-lg lg:w-[80%] bg-troo-white relative z-[1] -mt-20 rounded">
                <p>
                  23 July
                  <span className="p-1 bg-troo-black text-troo-white group-hover:bg-troo-primary">
                    
                    2023
                  </span>
                </p>
                <h4 className="group-hover:text-troo-primary">{e.name}</h4>
              </div>
            </Link>
          ))}
        </div>
        {isHome && (
          <div className="mt-12 text-center">
            <Link to="/our-blogs" className="btn1 bg-transparent  border-troo-primary hover:bg-troo-primary hover:text-troo-white">
              View All Blogs & News
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Blogs;
