import work1 from "../images/work1.png"
import work2 from "../images/work2.png"
import work3 from "../images/work3.png"
import work4 from "../images/work4.png"
import work5 from "../images/work5.png"
import work6 from "../images/work6.png"
import work7 from "../images/work7.png"
import work8 from "../images/work8.png"
import work9 from "../images/work9.png"

export const WorkData = [
    {
        id : 1,
        img : work1,
        name : "Aesthetic Dentistry",
        slug : "aesthetic-dentistry",
        sub : "Prosthodontics Dentist",
    },
    {
        id : 2,
        img : work2,
        name : "What to know about glossitis",
        slug : "what-to-know-about-glossitis",
        sub : "Prosthodontics Dentist",
    },
    {
        id : 3,
        img : work3,
        name : "What to know about glossitis",
        slug : "what-to-know-about-glossitis",
        sub : "Prosthodontics Dentist",
    },
    {
        id : 4,
        img : work4,
        name : "Aesthetic Dentistry",
        slug : "aesthetic-dentistry",
        sub : "Prosthodontics Dentist",
    },
    {
        id : 5,
        img : work5,
        name : "Aesthetic Dentistry",
        slug : "aesthetic-dentistry",
        sub : "Prosthodontics Dentist",
    },
    {
        id : 6,
        img : work6,
        name : "What to know about glossitis",
        slug : "what-to-know-about-glossitis",
        sub : "Prosthodontics Dentist",
    },
    {
        id : 7,
        img : work7,
        name : "Aesthetic Dentistry",
        slug : "aesthetic-dentistry",
        sub : "Prosthodontics Dentist",
    },
    {
        id : 8,
        img : work8,
        name : "What to know about glossitis",
        slug : "what-to-know-about-glossitis",
        sub : "Prosthodontics Dentist",
    },
    {
        id : 9,
        img : work9,
        name : "Aesthetic Dentistry",
        slug : "aesthetic-dentistry",
        sub : "Prosthodontics Dentist",
    },
]