import React from "react";
import blogDetailImg from "../images/blogDetailsImg.png";
import { BsCheck2Circle } from "react-icons/bs";
import bDetails1 from "../images/Bdetails1.png";
import bDetails2 from "../images/Bdetails2.png";
import { FaQuoteLeft } from "react-icons/fa";

const BlogDetails = () => {
  return (
    <div className="pt-12 ">
      <div className="main_container1 ">
        <div className="flex flex-col gap-5 py-12 border-b border-troo-black border-opacity-20">
          <div className="flex justify-center py-5 border-y border-troo-black border-opacity-20">
            <p>
              By: Vindy Devid - Published On: October 4, 2023 - Categories:
              Dental Care
            </p>
          </div>
          <div>
            <img className="w-full" src={blogDetailImg} alt="blogDetailsImg" />
          </div>
          <h2>4 Questions You Should Consider Choosing a Dentist</h2>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet tend
            to repeat predefined chunks as necessary, making this the first true
            generator on the Internet. It uses a dictionary of over 200 Latin
            words, combined with a handful of model sentence structures.
          </p>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum.
          </p>
          <h3>Your Title Goes Here</h3>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet tend
            to repeat predefined chunks as necessary, making this the first true
            generator on the Internet. It uses a dictionary of over 200 Latin
            words, combined with a handful of model sentence structures.
          </p>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum.
          </p>
          <h3>Your Title Goes Here</h3>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text.
          </p>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable.
          </p>
          <h3>Your Title Goes Here</h3>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet tend
            to repeat predefined chunks as necessary, making this the first true
            generator on the Internet. It uses a dictionary of over 200 Latin
            words, combined with a handful of model sentence structures.
          </p>
          <h3>Your Title Goes Here</h3>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text.
          </p>
          <div className="flex flex-col md:flex-row gap-5">
            <div className="flex flex-col gap-4 lg:w-[40%]">
              <h3>Your Title Goes Here</h3>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the the majority have suffered majority have suffered
                alteration in some form.
              </p>
              <div className="flex flex-col gap-3">
                <div className="flex items-center gap-3">
                  <div>
                    <BsCheck2Circle className="text-troo-primary w-5 h-5" />
                  </div>
                  <p>Emergency response time.</p>
                </div>
                <div className="flex items-center gap-3">
                  <div>
                    <BsCheck2Circle className="text-troo-primary w-5 h-5" />
                  </div>
                  <p>Until the eleifend elit is a lot of.</p>
                </div>
                <div className="flex items-center gap-3">
                  <div>
                    <BsCheck2Circle className="text-troo-primary w-5 h-5" />
                  </div>
                  <p>Lorem Ipsum is simply.</p>
                </div>
                <div className="flex items-center gap-3">
                  <div>
                    <BsCheck2Circle className="text-troo-primary w-5 h-5" />
                  </div>
                  <p>Lorem Ipsum available, but the.</p>
                </div>
              </div>
            </div>
            <div>
              <img src={bDetails1} alt="bDetails1" />
            </div>
            <div>
              <img src={bDetails2} alt="bDetails2" />
            </div>
          </div>
          <div className="bg-troo-tertiary p-8 flex gap-5 flex-col rounded">
            <FaQuoteLeft />
            <p className="font-bold">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Vestibulum velit tortor, interdum sed cursus eu, sagittis ut nunc.
              Sed vitae tellus et arcu aliquet{" "}
              <span className="text-troo-primary">faucibus fermentum</span> non
              lacus.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              VVestibulum velit torto
            </p>
          </div>
          <h3>Your Title Goes Here</h3>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text.
          </p>
        </div>
        <div className="">

        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
