import React from "react";
import logo from "../images/Logo2.svg";
import { BiTime } from "react-icons/bi";
import { TbMailOpened } from "react-icons/tb";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import { HeaderData } from "../Data/HeaderData";
const Footer = () => {
  const siteLink = "https://www.troothemes.com/";
  return (
    <div className="lg:bg-footer-Bg bg-no-repeat bg-cover w-full h-full pt-12 bg-troo-primary">
      <div className="main_container1">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 py-14 text-troo-white">
          <div className="flex flex-col sm:flex-row gap-10 justify-between">
            <div className=" flex flex-col gap-5 sm:w-[70%]">
              <div>
                <img src={logo} alt="logo2" />
              </div>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer.
              </p>
              <h4>24 X 7 Hours Emergency Services</h4>
            </div>
            <div className="flex flex-col sm:w-[30%]">
              <h4 className="relative before:absolute before:content-[''] before:bottom-0 before:left-0 before:w-5 before:h-[3px] before:bg-troo-white pb-3 mb-5">
                Our Services
              </h4>
              <div className="flex flex-col gap-3">
                <Link>Dental check-ups</Link>
                <Link>Root Canal Therapy</Link>
                <Link>Tooth Extractions</Link>
                <Link>Teeth Whitening</Link>
                <Link>Dental Sealants</Link>
                <Link>Dental Implants</Link>
              </div>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row gap-10 justify-between">
            <div className="flex flex-col sm:w-[40%]">
              <h4 className="relative before:absolute before:content-[''] before:bottom-0 before:left-0 before:w-5 before:h-[3px] before:bg-troo-white pb-3 mb-5">
                Contact Info.
              </h4>
              <div className="flex flex-col gap-8">
                <div className="flex items-center gap-5">
                  <div>
                    <TbMailOpened className="text-2xl" />
                  </div>
                  <div>
                    <p>4516 School Street Danbury, CT, Canada</p>
                  </div>
                </div>
                <div className="flex items-center gap-5">
                  <div>
                    <BiTime className="text-2xl" />
                  </div>
                  <div>
                    <p>+44 123 456 7890</p>
                  </div>
                </div>
                <div className="flex items-center gap-5">
                  <div>
                    <TbMailOpened className="text-2xl" />
                  </div>
                  <div>
                    <p>contact@troothemes.com</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col sm:w-[60%]">
              <div className="border-8 border-troo-white">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7343.710423642854!2d72.49508733426413!3d23.029087824997855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9b278e046fa5%3A0xd837def63555f791!2sTRooInbound%20Private%20Limited!5e0!3m2!1sen!2sin!4v1687782488933!5m2!1sen!2sin"
                  width="100%"
                  height="250"
                  style={{ border: "0" }}
                  allowfullscreen=""
                  loading="lazy"
                  title="gmap"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <div className="py-5 border-y border-troo-white border-opacity-20 flex lg:flex-row flex-col justify-between gap-8">
          <div className="flex flex-wrap justify-center gap-5 text-troo-white">
            {
              HeaderData.map((e,i)=>
              <Link>{e.title}</Link>
              )
            }
          </div>
          <div className="flex justify-center gap-5">
            <div className="text-troo-white bg-troo-whiteFade hover:bg-troo-secondary cursor-pointer p-2 rounded transition-all ease-in-out duration-300">
              <FaFacebookF />
            </div>
            <div className="text-troo-white bg-troo-whiteFade hover:bg-troo-secondary cursor-pointer  p-2 rounded transition-all ease-in-out duration-300">
              <FaInstagram />
            </div>
            <div className="text-troo-white bg-troo-whiteFade hover:bg-troo-secondary cursor-pointer p-2 rounded transition-all ease-in-out duration-300">
              <FaTwitter />
            </div>
            <div className="text-troo-white bg-troo-whiteFade hover:bg-troo-secondary cursor-pointer p-2 rounded transition-all ease-in-out duration-300">
              <FaLinkedinIn />
            </div>
          </div>
        </div>
        <div className="flex justify-center py-5">
          <p className="text-troo-white">Copyright © 2024. <Link to={siteLink} className="font-bold text-troo-secondary">TRooThemes</Link>. All rights reserved.</p>

        </div>
      </div>
    </div>
  );
};

export default Footer;
