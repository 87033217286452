import React, { useEffect, useState } from "react";
import Heading from "./Heading";
import { TeamData } from "../Data/TeamData";
import { Link } from "react-router-dom";

const Team = ({ isHome }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (isHome) {
      setData(TeamData.slice(0, 3));
    } else {
      setData(TeamData);
    }
  }, []);

  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        {isHome && (
          <div className="mb-12">
            <Heading
              title="It is a timer for a smile"
              isCenter
              heading={
                <>
                  A Team Commited to Care <br /> Your Dental Care
                </>
              }
            />
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {data.map((e, i) => (
            <Link to={`/our-team/${e.slug}`}
              className="relative group before:content-[''] before:absolute before:w-full hover:before:h-full before:h-0 before:bg-troo-black before:bg-opacity-70  before:transition-all before:ease-in-out before:duration-500 overflow-hidden"
              key={i}
            >
              <img className="w-full" src={e.img} alt={e.name} />
              <div className="absolute -bottom-32 group-hover:bottom-0 transition-all ease-in-out duration-500 left-1/2 -translate-x-1/2 bg-troo-primary flex flex-col items-center gap-3 p-5 text-troo-white w-[80%] rounded ">
                <h3>{e.name}</h3>
                <p>{e.sub}</p>
              </div>
              <div className="absolute top-0 -left-14 group-hover:left-0 p-3 flex flex-col gap-3 bg-troo-primary text-troo-white transition-all duration-500 ease-in-out">
                {e.social.map((ele) => (
                  <Link className="bg-troo-whiteFade p-2 rounded hover:bg-troo-secondary transition-all ease-in-out duration-500">
                    {ele.icon}
                  </Link>
                ))}
              </div>
            </Link>
          ))}
        </div>
        {isHome && (
          <div className="mt-12 text-center">
            <Link to="/our-team" className="btn1 bg-transparent  border-troo-primary hover:bg-troo-primary hover:text-troo-white">
              View All Members
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Team;
