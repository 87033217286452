import React, { useState } from "react";
import Layout from "../../Components/Layout";
import HeroGlobal from "../../Components/HeroGlobal";
import { FiMinus, FiPlus } from "react-icons/fi";
import { FAQData } from "../../Data/FAQData";
import { BiTime } from "react-icons/bi";
import { TbMailOpened } from "react-icons/tb";
import useDocumentTitle from "../../Hooks/PageTitle";

const Accordion = ({ item, index, isActive, toggleAccordion }) => (
  <div
    className="hs-accordion px-5 py-4  rounded bg-troo-tertiary"
    key={`accordion-${index}`}
    id={`hs-basic-with-title-and-arrow-stretched-heading-${index}`}
  >
    <button
      className="hs-accordion-toggle inline-flex items-center justify-between gap-x-3 w-full font-semibold text-start rounded-lg "
      onClick={() => toggleAccordion(index)}
      aria-controls={`hs-basic-with-title-and-arrow-stretched-collapse-${index}`}
    >
      <h4 className={`${isActive ? "text-troo-primary" : ""}`}>{item.name}</h4>
      {isActive ? (
        <FiMinus className="bg-troo-primary w-8 h-8 p-2 rounded-full text-troo-white" />
      ) : (
        <FiPlus className="w-8 h-8 p-2 rounded-full bg-troo-black bg-opacity-15" />
      )}
    </button>
    <div
      id={`hs-basic-with-title-and-arrow-stretched-collapse-${index}`}
      className={`hs-accordion-content w-full overflow-hidden transition-height duration-300 ease-in-out ${
        isActive ? "max-h-96" : "max-h-0"
      }`}
      aria-labelledby={`hs-basic-with-title-and-arrow-stretched-heading-${index}`}
    >
      <p className="mt-3 bg-troo-white p-5 rounded border-l border-troo-secondary">
        {item.para}
      </p>
    </div>
  </div>
);

const FaqPage = () => {
  useDocumentTitle("TRoo Dental | FAQ Page")
  const [activeAccordion1, setActiveAccordion1] = useState(0);

  const toggleAccordion = (index, setFunction) => {
    setFunction((prevIndex) => (prevIndex === index ? -1 : index));
  };
  return (
    <Layout>
      <HeroGlobal title="Questions and Answers" />
      <div className="py-12 lg:py-24">
        <div className="main_container1">
          <div className="flex flex-col gap-5">
            {FAQData.map((item, index) => (
              <Accordion
                key={index}
                item={item}
                index={index}
                isActive={activeAccordion1 === index}
                toggleAccordion={() =>
                  toggleAccordion(index, setActiveAccordion1)
                }
              />
            ))}
          </div>
          <div className="flex flex-col gap-5 justify-center items-center mt-12">
            <h3>Still Have You Questions?</h3>
            <div>
              <div className="bg-troo-primary p-5 rounded">
                <div className="bg-troo-secondary bg-opacity-20 rounded flex flex-col sm:flex-row p-5 text-troo-white gap-8">
                  <div className="flex gap-4 items-center">
                    <div>
                      <BiTime className="w-12 h-12 p-2 rounded-full  bg-troo-white bg-opacity-10"/>
                    </div>
                    <div className="flex flex-col ">
                      <h6>Call Us on</h6>
                      <h5>+44 123 456 7890</h5>
                    </div>
                  </div>
                  
                  <div className="flex gap-4 items-center">
                    <div>
                      <TbMailOpened className="w-12 h-12 p-2 rounded-full  bg-troo-white bg-opacity-10"/>
                    </div>
                    <div className="flex flex-col">
                      <h6>Call Us on</h6>
                      <h5>+44 123 456 7890</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FaqPage;
