export const PricingData = [
  {
    id: 1,
    name: "Basic Plan",
    price: "$150.00",
    para: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
    highlights: [
      {
        heading: "Stylish makeup for bride",
        className: "flex items-center gap-4",
      },
      {
        heading: "Top label arrangement",
        className: "flex items-center gap-4",
      },
      {
        heading: "Amazing meal & breakfast",
        className: "flex items-center gap-4 opacity-20",
      },
      {
        heading: " Manicure & Pedicure",
        className: "flex items-center gap-4 opacity-20",
      },
      {
        heading: "Full body Polish",
        className: "flex items-center gap-4 opacity-20",
      },
    ],
  },
  {
    id: 2,
    name: "Premium Plan",
    price: "$59.99",
    para: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
    highlights: [
      {
        heading: "Stylish makeup for bride",
        className: "flex items-center gap-4",
      },
      {
        heading: "Top label arrangement",
        className: "flex items-center gap-4",
      },
      {
        heading: "Amazing meal & breakfast",
        className: "flex items-center gap-4",
      },
      {
        heading: " Manicure & Pedicure",
        className: "flex items-center gap-4 opacity-20",
      },
      {
        heading: "Full body Polish",
        className: "flex items-center gap-4 opacity-20",
      },
    ],
  },
  {
    id: 3,
    name: "Family Plan",
    price: "$89.99",
    para: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
    highlights: [
      {
        heading: "Stylish makeup for bride",
        className: "flex items-center gap-4",
      },
      {
        heading: "Top label arrangement",
        className: "flex items-center gap-4",
      },
      {
        heading: "Social media integration",
        className: "flex items-center gap-4 opacity-20",
      },
      {
        heading: " Awesome Features",
        className: "flex items-center gap-4 opacity-20",
      },
      {
        heading: " Manicure & Pedicure",
        className: "flex items-center gap-4 opacity-20",
      },
    ],
  },
  {
    id: 4,
    name: "Corporate Plan",
    price: "$39.99",
    para: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
    highlights: [
      {
        heading: "Stylish makeup for bride",
        className: "flex items-center gap-4",
      },
      {
        heading: "Top label arrangement",
        className: "flex items-center gap-4",
      },
      {
        heading: "Social media integration",
        className: "flex items-center gap-4",
      },
      {
        heading: " Awesome Features",
        className: "flex items-center gap-4 opacity-20",
      },
      {
        heading: " Manicure & Pedicure",
        className: "flex items-center gap-4 opacity-20",
      },
    ],
  },
  {
    id: 5,
    name: "Super Plan",
    price: "$59.99",
    para: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
    highlights: [
      {
        heading: "Full body Polish",
        className: "flex items-center gap-4",
      },
      {
        heading: "Top label arrangement",
        className: "flex items-center gap-4",
      },
      {
        heading: "Social media integration",
        className: "flex items-center gap-4",
      },
      {
        heading: " Awesome Features",
        className: "flex items-center gap-4 opacity-20",
      },
      {
        heading: " Manicure & Pedicure",
        className: "flex items-center gap-4 opacity-20",
      },
    ],
  },
  {
    id: 6,
    name: "Ultimate Plan",
    price: "$69.99",
    para: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
    highlights: [
      {
        heading: "Stylish makeup for bride",
        className: "flex items-center gap-4",
      },
      {
        heading: "Top label arrangement",
        className: "flex items-center gap-4",
      },
      {
        heading: "Social media integration",
        className: "flex items-center gap-4",
      },
      {
        heading: " Awesome Features",
        className: "flex items-center gap-4",
      },
      {
        heading: " Manicure & Pedicure",
        className: "flex items-center gap-4",
      },
    ],
  },
];
