import React, { useEffect, useRef } from "react";
import logo from "../images/Logo.svg";
import humburger from "../images/Humberger.svg";
import { RiMenu3Fill } from "react-icons/ri";
import { FiMail, FiUser } from "react-icons/fi";
import { HeaderData } from "../Data/HeaderData";
import { Link } from "react-router-dom";
import NavMenu from "./NavMenu";
import { useLocation } from "react-router-dom";
import call from "../images/call.svg";
import AccordianMenu from "./AccordianMenu";

const Header = () => {
  const location = useLocation();
  let arr = location.pathname?.split("/");
  let lastvalue = arr[arr.length - 1].trim().replace(/-/g, " ");

  const navbar_ref = useRef();
  function handleScroll() {
    if (window.pageYOffset > 10 || document.documentElement.scrollTop > 10) {
      if (navbar_ref.current) {
        navbar_ref.current.style.top = "0px";
      }
    } else {
      if (navbar_ref.current) {
        navbar_ref.current.style.top = "-150px";
      }
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="show_nav" ref={navbar_ref}>
        <header className=" z-10 w-full flex gap-8">
          <div className="main_container2 py-3">
            <nav>
              <div className="flex justify-between items-center">
                <div className="mr-7 xl:mr-20">
                  <Link to="/">
                    <img
                      className="w-48 sm:w-[280px] 2xl:w-[95%]"
                      src={logo}
                      alt="logo"
                    />
                  </Link>
                </div>
                <div className="lg:flex hidden items-center gap-12">
                  <ul className="flex h-full items-center justify-between">
                    {HeaderData.slice(0, 6).map((item, i) => {
                      return <NavMenu key={i} item={item} />;
                    })}
                    <Link to="/book-an-appointmen" className="btn1 bg-troo-primary hover:bg-troo-secondary text-troo-white">
                      {HeaderData.slice(-1).map((e) => e.title)}
                    </Link>
                  </ul>
                  <div className="hidden xl:flex gap-8">
                    <div className=" hidden 2xl:flex gap-5 items-center">
                      <div className="p-2 bg-troo-primary bg-opacity-10 rounded">
                        <img src={call} alt="call" />
                      </div>
                      <div>
                        <h6 className="font-normal">
                          Get Emergencyr? Call Us Today
                        </h6>
                        <h4>+820 123 456 7890</h4>
                      </div>
                    </div>
                    <div
                      className="flex items-center"
                      data-hs-overlay="#hs-overlay-right"
                    >
                      <img src={humburger} alt="humburger" />
                    </div>
                  </div>
                </div>
                <button
                  className="md:block lg:hidden text-troo-primary drawer-content"
                  data-hs-overlay="#hs-overlay-left"
                >
                  <RiMenu3Fill className="text-[30px] cursor-pointer" />
                </button>
              </div>
            </nav>
          </div>
        </header>

        <div
          id="hs-overlay-left"
          class="hs-overlay hs-overlay-open:translate-x-0  -translate-x-full fixed top-0 start-0 transition-all duration-300 transform h-full max-w-xs w-full z-[60] hidden bg-troo-black px-4 py-5 shadow-2xl border-r border-troo-white border-opacity-10  [--overlay-backdrop:false] [--body-scroll:true]"
          tabindex="-1"
        >
          <div class="flex justify-between items-center mb-8">
            <img src={logo} alt="brand logo" className="w-48" />
            <button
              type="button"
              class="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-troo-white hover:text-troo-primary"
              data-hs-overlay="#hs-overlay-left"
            >
              <span class="sr-only">Close modal</span>
              <svg
                class="w-3.5 h-3.5"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                  fill="currentColor"
                />
              </svg>
            </button>
          </div>
          <div class="flex flex-col items-start gap-3 text-troo-white px-4 mt-5">
            {HeaderData.slice(0, 6).map((e, i) => {
              return !e?.subNav ? (
                <Link
                  key={i}
                  to={e.path}
                  className={`flex items-center capitalize text-base ${
                    e.title === lastvalue ? "text-troo-primary" : ""
                  }`}
                >
                  {e.title}
                </Link>
              ) : (
                <AccordianMenu title={e.title} subMenu={e.subNav} />
              );
            })}
          </div>
        </div>
      </div>

      <div>
        <header className="absolute z-10 w-full  border-b border-troo-primary border-opacity-30 flex gap-8">
          <div className="main_container2 py-3">
            <nav>
              <div className="flex justify-between items-center">
                <div className="mr-7 xl:mr-20">
                  <Link to="/">
                    <img
                      className="w-48 sm:w-[280px] 2xl:w-[95%]"
                      src={logo}
                      alt="logo"
                    />
                  </Link>
                </div>
                <div className="lg:flex hidden items-center gap-12">
                  <ul className="flex items-center h-full">
                    {HeaderData.slice(0, 6).map((item, i) => {
                      return <NavMenu key={i} item={item} />;
                    })}
                    <Link to="/book-an-appointmen" className="btn1 bg-troo-primary hover:bg-troo-secondary text-troo-white">
                      {HeaderData.slice(-1).map((e) => e.title)}
                    </Link>
                  </ul>
                   <div className=" hidden xl:flex gap-8">
                   <div className="hidden 2xl:flex gap-5 items-center">
                      <div className="p-2 bg-troo-primary bg-opacity-10 rounded">
                        <img src={call} alt="call" />
                      </div>
                      <div>
                        <h6 className="font-normal">
                          Get Emergencyr? Call Us Today
                        </h6>
                        <h4>+820 123 456 7890</h4>
                      </div>
                    </div>
                    <div
                      className="flex items-center"
                      data-hs-overlay="#hs-overlay-right"
                    >
                      <img src={humburger} alt="humburger" />
                    </div>
                   </div>
                </div>
                <button
                  className="md:block lg:hidden text-troo-primary drawer-content"
                  data-hs-overlay="#hs-overlay-left"
                >
                  <RiMenu3Fill className="text-[30px] cursor-pointer" />
                </button>
              </div>
            </nav>
          </div>
        </header>
        <div
          id="hs-overlay-right"
          class="hs-overlay hs-overlay-open:translate-x-0 hidden translate-x-full fixed top-0 end-0 transition-all duration-300 transform h-full max-w-sm z-[9999] bg-white dark:bg-gray-800 dark:border-gray-700 "
          tabindex="-1"
        >
          <div class="flex justify-between items-center py-5 px-4 border-b ">
            <div className="flex justify-center items-center">
              <img className="w-60" src={logo} alt="logo" />
            </div>
            <button
              type="button"
              class="flex justify-center items-center w-7 h-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              data-hs-overlay="#hs-overlay-right"
            >
              <span class="sr-only">Close modal</span>
              <svg
                className="flex-shrink-0 w-4 h-4 text-troo-white"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M18 6 6 18" />
                <path d="m6 6 12 12" />
              </svg>
            </button>
          </div>
          <div class="p-4">
            <div className="flex flex-col justify-center py-10 gap-6">
              <h3 className="text-center">About Us</h3>
              <p className="text-center">
                Lörem ipsum od ohet dilogi. Bell trabel, samuligt, ohöbel utom
                diska. FAR paratyp i muvåning, och pesask vyfisat. Viktiga
                poddradio har un mad och inde.
              </p>

            </div>
            <div>
              <h3 className="text-center">Get a Free Quote</h3>
              <div className="flex flex-col items-center gap-5 pt-6">
                <div className="relative w-full ">
                  <p className="mb-3">Full Name</p>
                  <FiUser className="absolute top-[70%] left-3 transform -translate-y-1/2" />
                  <input
                    type="text"
                    placeholder="Full Name"
                    className="p-4 pl-10 bg-transparent border border-troo-hrtag rounded w-full placeholder:text-troo-secondary focus:outline-none focus-visible:ring-1"
                  />
                </div>
                <div className="relative w-full">
                  <p className="mb-3">Your Email</p>
                  <FiMail className="absolute top-[70%] left-3 transform -translate-y-1/2" />
                  <input
                    type="email"
                    placeholder="Your Email Address"
                    className="p-4 pl-10 bg-transparent border border-troo-hrtag rounded w-full placeholder:text-troo-secondary focus:outline-none focus-visible:ring-1"
                  />
                </div>
                <div className="w-full">
                  <p className="mb-3">Write Comment</p>
                  <textarea
                    placeholder="White something to me"
                    className="p-5 bg-transparent border focus:outline-none focus-visible:ring-1 rounded w-full"
                    rows={3}
                  />
                </div>
                <div>
                  <Link className="btn1 w-full text-center bg-troo-secondary text-troo-white  hover:bg-troo-primary">
                    Send Message
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
