import React from "react";
import { ServiceData } from "../Data/ServiceData";
import serviceDetailsImg from "../images/serviceDetailsImg.png";
import serviceDetailsImg2 from "../images/serviceDetailsPage2.png";
import Input from "./Input";
import { BsPerson, BsTelephone } from "react-icons/bs";
import { IoMailOpenOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";

const ServiceDetails = () => {
  const param = useParams()
  console.log(param);

  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="flex flex-col lg:flex-row h-full items-start gap-6 ">
          <div className="w-full lg:w-[70%] flex flex-col items-start gap-5">
            <h2>What is general dentistry?</h2>
            <h4>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout.
            </h4>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English.
            </p>
            <p>
              general dentistry services consist of elements that are vital to
              your continued health, including:
            </p>
            <h3>Preventive services:</h3>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything embarrassing
              hidden in the middle of text. All the Lorem Ipsum generators on
              the Internet tend to repeat predefined chunks as necessary, making
              this the first true generator on the Internet. It uses a
              dictionary of over 200 Latin words, combined with a handful of
              model sentence structures.
            </p>
            <h3>Restorative Services</h3>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything embarrassing
              hidden in the middle of text. All the Lorem Ipsum generators on
              the Internet tend to repeat predefined chunks as necessary.
            </p>
            <h3>Cosmetic Procedures</h3>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything embarrassing
              hidden in the middle of text. All the Lorem Ipsum generators on
              the Internet tend to repeat predefined chunks as necessary, making
              this the first true generator on the Internet.
            </p>
            <h3>What We Do</h3>
            <div className="flex flex-col md:flex-row gap-5">
              {ServiceData.slice(0, 3).map((ele) => (
                <div className="bg-troo-tertiary flex flex-col items-center gap-5 p-6">
                  <div className="bg-troo-primary rounded-full w-12 h-12 flex justify-center items-center p-2">
                    <img src={ele.sIcon} alt="icons" />
                  </div>
                  <h4>{ele.name}</h4>
                  <p className="text-center">{ele.para}</p>
                </div>
              ))}
            </div>
            <h3>General Dentistry Result</h3>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything embarrassing
              hidden in the middle of text. All the Lorem Ipsum generators on
              the Internet tend to repeat predefined chunks as necessary, making
              this the first true generator on the Internet.
            </p>
            <img src={serviceDetailsImg} alt="serviceDetailsImg" />
          </div>
          <div className="w-full md:sticky top-40 lg:w-[30%]">
            <div className="mb-5">
              <img src={serviceDetailsImg2} alt="serviceDetailsImg" />
            </div>
            <div className="flex flex-col items-start gap-6 bg-troo-primary  p-8 mb-10">
              <h4 className="relative text-troo-white  pb-3">
                Quick An Appointment
              </h4>
              <div className="w-full">
                <form className="flex flex-col gap-3 w-full">
                  <Input
                    isservice={true}
                    placeholder="Full Name"
                    icon={<BsPerson />}
                  />
                  <Input
                    isservice={true}
                    placeholder="Email Adress"
                    icon={<IoMailOpenOutline />}
                  />
                  <Input
                    isservice={true}
                    placeholder="Phone No."
                    icon={<BsTelephone />}
                  />
                  <Input
                    isservice={true}
                    placeholder="Full Name"
                    icon={<BsPerson />}
                  />

                  <Input isservice={true} type="date" />
                  <Input isservice={true} type="time" />

                  <button className="btn1 flex justify-center bg-troo-secondary hover:bg-troo-primary text-troo-white border-none">
                    Book An Appointment
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetails;
