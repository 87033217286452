import React from 'react'
import Layout from '../../Components/Layout'
import HeroGlobal from '../../Components/HeroGlobal'
import Work from '../../Components/Work'
import useDocumentTitle from '../../Hooks/PageTitle'

const WorkPage = () => {
  useDocumentTitle("TRoo Dental | Case Study")
  return (
    <Layout>
        <HeroGlobal title="Latest Case Stadies" />
        <Work/>
    </Layout>
  )
}

export default WorkPage