import React from "react";
import Layout from "../Components/Layout";
import HeroSection from "../Components/HeroSection";
import Appointments from "../Components/Appointments";
import Service from "../Components/Service";
import Abouts from "../Components/Abouts";
import Work from "../Components/Work";
import Team from "../Components/Team";
import Testimonial from "../Components/Testimonial";
import Highlight from "../Components/Highlight";
import Blogs from "../Components/Blogs";
import Schedule from "../Components/Schedule";
import useDocumentTitle from "../Hooks/PageTitle";

const Home = () => {
  useDocumentTitle("TRoo Dental - React Js Template")
  return (
    <Layout>
      <HeroSection />
      <Appointments />
      <Service isHome/>
      <Abouts />
      <Work isHome/>
      <Team isHome/>
      <Testimonial isHome/>
      <Highlight />
      <Blogs isHome/>
      <Schedule/>
    </Layout>
  );
};

export default Home;
