import React from "react";
import { FiAward } from "react-icons/fi";
import { FiCheck } from "react-icons/fi";
import abouImg from "../images/aboutImg.png";
import Heading from "./Heading";
import { Link } from "react-router-dom";
import person from "../images/personImg.png";
import dotsGroup from "../images/dotsGroup.png"

const Abouts = () => {
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
          <div className="relative">
            <img className="w-full lg:w-auto"  src={abouImg} alt="aboutImg" />
            <div className="sm:absolute right-0 bottom-10  bg-troo-primary mt-5 sm:mt-0  sm:w-52  rounded">
              <div className="flex flex-col gap-5 items-center flex-wrap p-8 relative before:absolute before:content-[''] before:w-full before:h-full before:border before:border-troo-secondary before:left-2 before:top-2 before:rounded">
                <div className="text-troo-primary rounded-full bg-troo-white outline outline-4  outline-blue-600 w-14 h-14 flex justify-center items-center p-2">
                  <FiAward className="text-3xl" />
                </div>
                <h2 className="text-troo-white">20+</h2>
                <h3 className="font-normal text-troo-white text-center">
                  Year of Experience
                </h3>
              </div>
            </div>
            <div className="absolute -top-8 right-20">
                <img src={dotsGroup} alt="dotsGroup" />
            </div>
          </div>
          <div className="flex flex-col gap-8">
            <div>
              <Heading
                title="Learn About Us"
                heading="Quality Dental Care Starts With Quality Doctors"
              />
            </div>
            <h4>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout.
            </h4>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English.
            </p>
            <div className="flex flex-col md:flex-row gap-8">
              <div className="flex gap-5">
                <div>
                  <FiCheck className="rounded-full p-1 text-troo-primary bg-troo-primary bg-opacity-10 w-6 h-6" />
                </div>
                <p>
                  It is a long and we have established fact that a reader will
                  be distracted by the readable content of a page.
                </p>
              </div>
              <div className="flex gap-5">
                <div>
                  <FiCheck className="rounded-full p-1 text-troo-primary bg-troo-primary bg-opacity-10 w-6 h-6" />
                </div>
                <p>
                  It is a long and we have established fact that a reader will
                  be distracted by the readable content of a page.
                </p>
              </div>
            </div>
            <div className="flex sm:flex-row flex-col gap-10">
              <div>
              <Link to="/about-us" className="btn1 bg-transparent border-troo-primary hover:bg-troo-primary hover:text-troo-white">
                Explore More
              </Link>
              </div>
              <div className="flex gap-2">
                <img className="rounded-full" src={person} alt="aboutPerson" />
                <div>
                  <h4>William Boroman</h4>
                  <p>CEO and Founder</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Abouts;
