import React from 'react'
import Layout from '../../Components/Layout'
import HeroGlobal from '../../Components/HeroGlobal'
import Blogs from '../../Components/Blogs'
import useDocumentTitle from '../../Hooks/PageTitle'

const BlogPage = () => {
  useDocumentTitle("TRoo Dental | Blogs Page")
  return (
    <Layout>
        <HeroGlobal title="Read Latest Blogs, News and Article" />
        <Blogs/>
    </Layout>
  )
}

export default BlogPage