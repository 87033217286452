import React from 'react'
import Layout from '../../Components/Layout'
import HeroGlobal from '../../Components/HeroGlobal'
import ServiceDetails from '../../Components/ServiceDetails'
import useDocumentTitle from '../../Hooks/PageTitle'

const ServiceDetailsPage = () => {
  useDocumentTitle("TRoo Dental | Service Details")
  return (
    <Layout>
        <HeroGlobal title="General Dentistry" />
        <ServiceDetails/>
    </Layout>
  )
}

export default ServiceDetailsPage