import React from 'react'
import Layout from '../../Components/Layout'
import HeroGlobal from '../../Components/HeroGlobal'
import Team from '../../Components/Team'
import useDocumentTitle from '../../Hooks/PageTitle'

const TeamPage = () => {
  useDocumentTitle("TRoo Dental | Team Page")
  return (
    <Layout>
        <HeroGlobal title="Our Professional Dentist" />
        <Team/>
    </Layout>
  )
}

export default TeamPage