import { FiPhoneCall } from 'react-icons/fi';
import { HiOutlineMailOpen,HiOutlineLocationMarker } from 'react-icons/hi';


export const AddressData =[
    {
        id : 1,
        icon : <FiPhoneCall/>,
        name : "Need Help? Call Us on",
        para : "+44 123 456 7890"
    },
    {
        id : 2,
        icon : <HiOutlineMailOpen/>,
        name : "Email Us",
        para : "info@troothems.com"
    },
    {
        id : 1,
        icon : <HiOutlineLocationMarker/>,
        name : "Our Office Address",
        para : "4516 School Street Danbury, CT, Canada"
    },
]