import React from 'react'
import Layout from '../../Components/Layout'
import HeroGlobal from '../../Components/HeroGlobal'
import useDocumentTitle from '../../Hooks/PageTitle'

const ComingSoonPage = () => {
  useDocumentTitle("TRoo Dental | Coming Soon")
  return (
   <Layout>
    <HeroGlobal title="Coming Soon"/>
   </Layout>
  )
}

export default ComingSoonPage