import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

const Counter = () => {
  const [counter, setCounter] = useState(false);
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mt-24 bg-troo-white rounded">
      <div className="transition-all ease-in-out duration-500 group rounded-s">
        <div className="flex flex-col gap-4 items-center p-5 md:p-10 relative ">
          <h2 className="text-troo-primary lg:text-4xl text-3xl  transition-all duration-500 ease-in-out">
            <ScrollTrigger
              onEnter={() => setCounter(true)}
              onExit={() => setCounter(false)}
            >
              {counter && (
                <CountUp start={0} end={2} duration={3} delay={0} />
              )}
              <strong>K+</strong>
            </ScrollTrigger>
          </h2>
          <h4 className="text-troo-black">Cases completed</h4>
        </div>
      </div>

      <div className=" transition-all ease-in-out duration-500 group">
        <div className="flex flex-col gap-4 items-center p-5 md:p-10 relative ">
          <h2 className="text-troo-primary lg:text-4xl text-3xl  transition-all duration-500 ease-in-out">
            <ScrollTrigger
              onEnter={() => setCounter(true)}
              onExit={() => setCounter(false)}
            >
              {counter && <CountUp start={0} end={20} duration={3} delay={0} />}
              <strong>+</strong>
            </ScrollTrigger>
          </h2>
          <h4 className="text-troo-black">Years of Experience</h4>
        </div>
      </div>
      <div className=" transition-all ease-in-out duration-500 group rounded-e">
        <div className="flex flex-col gap-4 items-center p-5 md:p-10 relative ">
          <h2 className="text-troo-primary lg:text-4xl text-3xl  transition-all duration-500 ease-in-out">
            <ScrollTrigger
              onEnter={() => setCounter(true)}
              onExit={() => setCounter(false)}
            >
              {counter && (
                <CountUp start={0} end={18} duration={3} delay={0} />
              )}
              <strong>+</strong>
            </ScrollTrigger>
          </h2>
          <h4 className="text-troo-black">Awards Winning</h4>
        </div>
      </div>
      <div className=" transition-all ease-in-out duration-500 group rounded-e">
        <div className="flex flex-col gap-4 items-center p-5 md:p-10 relative ">
          <h2 className="text-troo-primary lg:text-4xl text-3xl  transition-all duration-500 ease-in-out">
            <ScrollTrigger
              onEnter={() => setCounter(true)}
              onExit={() => setCounter(false)}
            >
              {counter && (
                <CountUp start={0} end={100} duration={3} delay={0} />
              )}
              <strong>%</strong>
            </ScrollTrigger>
          </h2>
          <h4 className="text-troo-black">Clients Satisfied</h4>
        </div>
      </div>
    </div>
  );
};

export default Counter;
