import React from "react";
import Input from "./Input";
import { BsPerson, BsTelephone } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { TbMailOpened } from "react-icons/tb";

const Appointments = () => {
  return (
    <div className="bg-appointementBg bg-no-repeat py-12 bg-troo-primary">
      <div className="main_container1">
        <h2 className="text-troo-white pb-5">Book An Appointment</h2>
        <form className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
          <Input placeholder="Full Name" icon={<BsPerson />} />
          <Input placeholder="Email Adress" icon={<TbMailOpened />} />
          <Input placeholder="Phone No." icon={<BsTelephone />} />
          <Input placeholder="Company Name" icon={<FiEdit />} />
          <Input placeholder="Full Name" icon={<BsPerson />} />
          <Input placeholder="Email Adress" icon={<TbMailOpened />} />
          <Input placeholder="Phone No." icon={<BsTelephone />} />

          <button className="btn1 bg-troo-secondary border-troo-primary text-troo-white justify-center hover:bg-troo-black">
            Book An Appointment
          </button>
        </form>
      </div>
    </div>
  );
};

export default Appointments;
