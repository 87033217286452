import React from 'react'
import Layout from '../Components/Layout'
import HeroGlobal from '../Components/HeroGlobal'
import Abouts from '../Components/Abouts'
import Work from '../Components/Work'
import Team from '../Components/Team'
import Testimonial from '../Components/Testimonial'
import Highlight from '../Components/Highlight'
import Schedule from '../Components/Schedule'
import useDocumentTitle from '../Hooks/PageTitle'

const AboutPage = () => {
  useDocumentTitle("TRoo Dental | Contact Page")
  return (
    <Layout>
        <HeroGlobal title="Who We Are"/>
        <Abouts/>
        <Work/>
        <Team/>
        <Testimonial/>
        <Highlight/>
        <Schedule/>
    </Layout>
  )
}

export default AboutPage